<template>
  <div class="login">
    
    <video autoplay loop="loop" class="video-bg">
       <source src="/login.mp4" type="video/mp4"></source>
       </video>
      <div class="login-box">
        <div class="logo">
          <img src="static/images/logo.png" />
        </div>
        <div>百万精准流量就在万玻</div>
        <div class="body">
          <!-- <p class="tips">万玻传奇</p> -->
          <!-- <p class="tips">9万家中小玻璃厂的传奇</p> -->
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-form-item label="" prop="username">
              <el-input v-model="form.username" placeholder="账号"></el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input
                type="password"
                v-model="form.password"
                placeholder="密码"
              ></el-input>
            </el-form-item>
            <br />
            <el-form-item>
              <el-button
                type="primary"
                @click="startLogin"
                :loading="loading"
                style="width: 100%"
              >
                {{ loading ? "登录中..." : "立即登录" }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </video>
  </div>
</template>
<script>
import api from "@/config/api";

export default {
  data() {
    return {
      root: "",
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "密码不得低于6个字符", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },
  components: {},
  methods: {
    startLogin() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        let root = this.root;
        this.axios
          .post(root + "auth/login", {
            username: this.form.username,
            password: this.form.password,
          })
          .then((res) => {
            let call = res.data;
            this.loading = false;
            if (res.data.errno === 0) {
              localStorage.setItem("token", res.data.data.token);
              localStorage.setItem(
                "userInfo",
                JSON.stringify(res.data.data.userInfo)
              );
              if (!res.data.data.userInfo.main_business) {
                this.$router.push({ name: "company" });
              } else {
                this.$router.push({ name: "welcome" });
              }
            } else {
              this.$message({
                type: "error",
                message: call.errmsg,
              });
              return false;
            }
          })
          .catch((err) => {
            this.loading = false;
             this.$message({
                type: "error",
                message: '登录失败，请联系商务专员为您处理'
              });
          });
      });
    },
  },
  mounted() {
    this.root = api.rootUrl;
  },
};
</script>
<style>
.video-bg {
  min-height: 100%;
    position: fixed;
    right: 0px;
    bottom: 0px;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: 100%;
    z-index: 0;
}
.login {
  align-items: center;
  /* background: url("http://wfile.guyuyin.com/pexels-harrison-haines-2973098111.jpg"); //pexels-lukas-kloeppel-466685111 */
  /* 以上为登录背景,可以自己更换成自己喜欢的 */
  background: url('https://publicfile.m-glass.cn/fabca666-7cb4-40f3-989b-a4ddbd54be3c');
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  font-family: Lato, Helvetica, sans-serif;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  color: #656565;
}

.login-box {
  width: 320px;
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  /* box-shadow: 2px 2px 12px #ccc; */
  margin-left: 700px;
  /* margin-top: 10%; */
  z-index:1
}

.login-box .logo {
  height: 100px;
  padding-top: 30px;
  /*background: #324157;*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box .logo img {
  width: 80px;
  height: 80px;
}

.login-box .body {
  padding: 10px 30px 30px 30px;
}

.login-box .body .tips {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 30px;
}

.login-box .body .author {
  display: block;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #656565;
  margin-bottom: 10px;
  text-decoration: none;
}

.login-box .body .author a {
  text-decoration: none;
}
</style>
